import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.css']
})
export class NewTicketComponent implements OnInit {

  tickets: any[];
  instantView: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<NewTicketComponent>
  ) {}

  ngOnInit() {
    console.log(this.data)
    this.instantView = this.data.instantView;
    this.tickets = this.data.tickets;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  print() {
    window.print();
  }
}
