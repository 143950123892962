import { HttpClient, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import * as moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { NewTicketComponent } from "../new-ticket/new-ticket.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  public isCollapsed = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
    ) {}

  reprint(ticketDetails) {
      return this.dialog.open(NewTicketComponent, {
        panelClass: "dialog-container",
        disableClose: true,
        width: "100%",
        // position: { top: "10px" },
        data: {
          ...ticketDetails
          // tickets: ticketDetails.sc_tickets,
          // instantView: {
          //   ...ticketDetails.sc_instance_view,
          //   bookingDate: this.formatDate(ticketDetails.sc_instance_view.endTime),
          //   name: ticketDetails.customer_name,
          //   email: ticketDetails.customer_email,
          // },
        },
      });
    }

  toggleNav() {
    this.isCollapsed.next(true);
  }

  getToggleValue() {
    return this.isCollapsed.asObservable();
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user_id"));
  }

  getUserGroup() {
    return JSON.parse(localStorage.getItem("user_group"));
  }
  getUsername() {
    return JSON.parse(localStorage.getItem("username"));
  }
  getFirstname() {
    return JSON.parse(localStorage.getItem("firstname"));
  }
  getLastname() {
    return JSON.parse(localStorage.getItem("lastname"));
  }
  getPhone() {
    return JSON.parse(localStorage.getItem("phone"));
  }

  formatDate(date) {
    // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return moment(date).format("YYYY-MM-DD");
  }

  calenderDate(date) {
    return moment(date).calendar();
  }

  formateCalendarDate(date) {
    return moment(date).format('MMM Do YYYY');
  }

  formateCalendarDateWithTime(date) {
    return moment(date).format('MMMM Do YYYY, h:mm:ss');
  }

  formatScDisplayDate(date: string) {
    const dateString = String(date);
    return `${dateString.substring(0, 4)}-${dateString.substring(
      4,
      6
    )}-${dateString.substring(6, 8)}`;
  }

  formatScDate(date) {
    return moment(date).format("DD-MM-YYYY HH:MM:SS");
  }
  formatScStartDate(date) {
    return moment(date).startOf('day').format("DD-MM-YYYY HH:MM:SS");
  }
  formatScEndDate(date) {
    return moment(date).endOf('day').format("DD-MM-YYYY HH:MM:SS");
  }

  formatStartOfYear(date) {
    // return moment(date).startOf("year").format("YYYY-MM-DD");
    return `2021-01-12`;
  }

  formatStartOfQuater(date) {
    return moment(date).startOf("quarter").format("YYYY-MM-DD");
  }

  formatStartOfMonthDate(date) {
    return moment(date).startOf("month").format("YYYY-MM-DD");
  }

  formatStartOfWeekDate(date) {
    return moment(date).startOf("week").format("YYYY-MM-DD");
  }


  exportExcel(data, title, tableHeaders) {
    let options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: tableHeaders,
      title: title,
    };
    new AngularCsv(data, title, options);
  }

  exportPDF(data, title, tableHeaders) {
    let doc = new jsPDF();

    doc.setFontSize(18);
    doc.text(title, 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: tableHeaders,
      body: data,
      theme: "plain",
      didDrawCell: (data) => {
      },
    });

    // below line for Open PDF document in new tab
    doc.output("dataurlnewwindow");

    // below line for Download PDF document
    doc.save(`${title}.pdf`);
  }

  lowerCase(string: string) {
    return string.toLowerCase();
  }

  firstUpper(string: string) {
    const username = string.toLowerCase();
    return `${username.charAt(0).toUpperCase()}${username.slice(1)}`;
  }

  upperCase(string: string) {
    return string.toUpperCase();
  }

  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }
}
