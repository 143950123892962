import { Component, OnInit } from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { ErrorService } from "./shared/error/error.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "argon-dashboard-angular";

  constructor(private errorService: ErrorService, private authService: AuthService) {}

  ngOnInit() {
    this.authService.autoAuthUser()
    this.errorService
      .createOnline$()
      .subscribe((isOnline) => {
        isOnline ? null : alert (`Please check your internet connection`)
      });
  }
}
