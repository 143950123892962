// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: `https://report.tps.ng/`,
  // url: `https://trc-report0.herokuapp.com/`,
  scSoftUrl: `https://connect.tps.ng/`,
  // scSoftUrl: `https://cctest.tps.ng/`,
  version: "1.0.23",
  firebaseConfig : {
    apiKey: "AIzaSyAozDXg90c21KDWV-16rD0A-4lctZQOkao",
    authDomain: "fare-116f4.firebaseapp.com",
    projectId: "fare-116f4",
    storageBucket: "fare-116f4.appspot.com",
    messagingSenderId: "847312206505",
    appId: "1:847312206505:web:6494ac363cf9215333c7b8",
    measurementId: "G-8W9EMRR50R"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
