import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import { ComponentsModule } from "./components/components.module";
import { HighchartsChartModule } from "highcharts-angular";
import { CarouselModule } from "ngx-owl-carousel-o";
import { DashboardLineGraphComponent } from "./dashboard-line-graph/dashboard-line-graph.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ToastrModule } from "ngx-toastr";
import { ErrorInterceptor } from "./shared/error-interceptor";
import { CurrencyPipe } from "@angular/common";
import { AuthInterceptor } from "./auth/auth-interceptor";
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { ReviewListComponent } from './review-list/review-list.component';
import { RevenueStationTableComponent } from './revenue-station-table/revenue-station-table.component';
import { DashboardCardStatComponent } from './dashboard-card-stat/dashboard-card-stat.component';
import { CardRatingStatComponent } from './card-rating-stat/card-rating-stat.component';
import { StarComponent } from './card-rating-stat/star/star.component';
import { OldUsersComponent } from './old-users/old-users.component';
import { FlwSettlementComponent } from './flw-settlement/flw-settlement.component';
import { ManifestComponent } from './manifest/manifest.component';
import { ValidationTableComponent } from './validation-table/validation-table.component';
import { FailedBookingRevenueComponent } from './failed-booking-revenue/failed-booking-revenue.component';
import { OldUsersPipe } from './old-users/old-users.pipe';
import { ValidationDeviceCreateComponent } from './validation-device/validation-device-create/validation-device-create.component';
import { ValidationDeviceListComponent } from './validation-device/validation-device-list/validation-device-list.component';
import { BookedUserTableComponent } from './booked-user-table/booked-user-table.component';
import { OnTrainValidatedUserTableComponent } from './on-train-validated-user-table/on-train-validated-user-table.component';
import { OnTrainValidatedIssuesTableComponent } from './on-train-validated-issues-table/on-train-validated-issues-table.component';
import { LtsListComponent } from './lts-list/lts-list.component';
import { AdUploadComponent } from './ad-upload/ad-upload.component';
import { ScUserListComponent } from './sc-user-list/sc-user-list.component';
import { ScFetchTicketComponent } from './sc-fetch-ticket/sc-fetch-ticket.component';
import { environment } from "src/environments/environment";
import { BannerMessageListComponent } from './banner-message-list/banner-message-list.component';
import { BannerMessageCreateComponent } from './banner-message-create/banner-message-create.component';
import { AdPageCreateComponent } from './ad-page/ad-page-create/ad-page-create.component';
import { AdPageListComponent } from './ad-page/ad-page-list/ad-page-list.component';
import { AdLocationCreateComponent } from './ad-location/ad-location-create/ad-location-create.component';
import { AdLocationListComponent } from './ad-location/ad-location-list/ad-location-list.component';
import { NrcForceMajeureListComponent } from './nrc-force-majeure-list/nrc-force-majeure-list.component';
import { RescheduleBookingComponent } from './reschedule-booking/reschedule-booking.component';
import { SeatSelectorComponent } from './reschedule-booking/seat-selector/seat-selector.component';
import { CoachSelectorComponent } from './reschedule-booking/coach-selector/coach-selector.component';
import { SeatMapComponent } from './reschedule-booking/seat-map/seat-map.component';
import { SeatComponent } from './reschedule-booking/seat/seat.component';
import { OneSeatComponent } from './reschedule-booking/one-seat/one-seat.component';
import { TrainBoxComponent } from './reschedule-booking/train-box/train-box.component';
import { InstanceCardComponent } from './reschedule-booking/instance-card/instance-card.component';
import { ScUserEditComponent } from './sc-user-edit/sc-user-edit.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NrcForceMajeureTriggerComponent } from './nrc-force-majeure-trigger/nrc-force-majeure-trigger.component';
import { NrcForceMajeureReasonListComponent } from './nrc-force-majeure-reason-list/nrc-force-majeure-reason-list.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    HighchartsChartModule,
    CarouselModule,
    MatDialogModule,
    MatSnackBarModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    DashboardLineGraphComponent,
  ],
  entryComponents: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
