import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ErrorService } from "../shared/error/error.service";
import { HelperService } from "../shared/helper.service";
import { NotificationService } from "../shared/notification.service";
import { AuthLogin } from "./auth-model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.url;
  private accessToken: any = null;
  private isAuthenticated: boolean = false;
  private currentUserInfo: any;
  private tokenTimer: any;
  private isLoading: boolean;
  accessTokenSubject = new BehaviorSubject<any>(this.accessToken);
  private authStatusListener = new BehaviorSubject<boolean>(
    this.isAuthenticated
  );
  private isLoadingListener = new Subject<boolean>();
  refreshToken: any;
  userGroup: any;
  userId: any;
  errorMessage: string;
  private userGroupList: any[];
  private userGroupListListener = new Subject<any[]>()

  constructor(
    private router: Router,
    private http: HttpClient,
    private helperService: HelperService,
    private notificationService: NotificationService,
    private errorService: ErrorService
  ) {}

  getUserGroupList() {
    this.http.get(`${this.apiUrl}auth/group/`)
    .subscribe((response: any[]) => {
      console.log(response)
      this.userGroupList = response;
      this.userGroupListListener.next(this.userGroupList)
    })
  }

  getToken() {
    const token = this.accessToken || JSON.parse(localStorage.getItem("tokenAccess"))
    this.accessTokenSubject.next(token)
    return this.accessTokenSubject.asObservable();
  }

  getUserId() {
    return this.currentUserInfo;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getIsLoadingListener() {
    return this.isLoadingListener.asObservable();
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    // if (expiresIn > 0) {
      this.accessToken = authInformation.token;
      this.accessTokenSubject.next(this.accessToken);
      this.isAuthenticated = true;
      this.authStatusListener.next(this.isAuthenticated);
      this.currentUserInfo = Number(authInformation.userId);
      // this.timedLogOut(expiresIn / 1000);
    // }
  }

  private timedLogOut(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(
    token: string,
    refreshToken: string,
    expirationDate: Date,
    userId: number,
    userGroup: number,
    currentUserInfo: any
  ) {
    localStorage.setItem("tokenAccess", token);
    localStorage.setItem("tokenRefresh", refreshToken);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("user_id", JSON.stringify(userId));
    localStorage.setItem("currentUserInfo", JSON.stringify(currentUserInfo));
    localStorage.setItem("user_group", JSON.stringify(userGroup));
  }

  private saveAuthData2(
    token: string,
    expirationDate: Date,
    customerUserInfo: any,
    isAuth: boolean
  ) {
    localStorage.setItem("tokenAccess", token);
    localStorage.setItem("isAuth", JSON.stringify(isAuth));
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("customerUserInfo", JSON.stringify(customerUserInfo));
  }

  private getAuthData() {
    const token = localStorage.getItem("tokenAccess");
    const refresh = localStorage.getItem("tokenRefresh");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("user_id");
    const userGroup = localStorage.getItem("user_group");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      userGroup: userGroup,
      refresh,
    };
  }

  getUserGroupExt() {
    const token = localStorage.getItem("tokenAccess");
    const expirationDate = localStorage.getItem("expiration");
    const userGroup = JSON.parse(localStorage.getItem("user_group"));
    if (!token || !expirationDate) {
      return;
    }
    return userGroup;
  }

  // getUserGroup(res: any) {
  //   if (res.groups[0] === 1) {
  //     this.isRootAdmin = true;
  //     this.isAdmin = false;
  //   } else if (res.groups[0] === 2) {
  //     this.isAdmin = true;
  //     this.isRootAdmin = false;
  //   } else {
  //     // something something
  //   }
  // }

  getUserID() {
    const userId = localStorage.getItem("user_id");
    return userId;
  }

  private clearAuthData() {
    localStorage.removeItem("isAuth");
    localStorage.removeItem("tokenRefresh");
    localStorage.removeItem("tokenAccess");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("userGroup");
    localStorage.removeItem("currentUserInfo");
    localStorage.clear();
  }

  login(form: AuthLogin) {
    // const data = {
    //   email: form.username,
    //   password: form.password,
    // };
    // // console.log(data);
    this.http.post(`${this.apiUrl}auth/login/`, form).subscribe(
      (res: any) => {
        // console.log(res);
        this.accessToken = res.access;
        this.accessTokenSubject.next(this.accessToken);
        if (this.accessToken) {
          this.isAuthenticated = true;
          this.authStatusListener.next(this.isAuthenticated);
          this.userGroup = res.groups[0];
          this.userId = res.id;
          this.refreshToken = res.refresh;
          this.currentUserInfo = {
            username: res.username,
            first_name: res.first_name,
            last_name: res.last_name,
          };
          const expiresInDuration = 1800;
          this.timedLogOut(expiresInDuration);
          const now = new Date();
          let expirationDate = new Date(
            now.getTime() + expiresInDuration * 1000
          );
          this.saveAuthData(
            this.accessToken,
            this.refreshToken,
            expirationDate,
            this.userId,
            this.userGroup,
            this.currentUserInfo
          );
          this.router.navigate(["/dashboard"]);
          // if (this.userGroup.id == 1) {
          // } else {
          //   this.router.navigate(["/daily-sales-report"]);
          // }
        }
      },
      (err) => {
        // console.log(err.error);
        err.error.message ? this.errorMessage = err.error.message : this.errorMessage = "Sorry, an error occured";
        this.isLoadingListener.next(false);
        this.errorService.handleError2(this.errorMessage);
      }
    );
  }

  logout() {
    this.accessToken = null;
    this.accessTokenSubject.next(this.accessToken);
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.currentUserInfo = null;
    this.clearAuthData();
    localStorage.clear();
    this.router.navigate(["/login"]);
    clearTimeout(this.tokenTimer);
  }
}
