import { Injectable } from "@angular/core";
// import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    // public snackBar: MatSnackBar,
    private toastr: ToastrService
  ) {}

  // private config: MatSnackBarConfig = {
  //   duration: 10000,
  //   horizontalPosition: "right",
  //   verticalPosition: "top",
  // };

  private options = {
    timeOut: 5000,
    closeButton: true,
  };

  success(msg) {
    this.toastr.success("Success!", msg, this.options);
    // this.config["panelClass"] = ["notification", "success"];
    // this.snackBar.open(msg, "", this.config);
  }

  info(msg) {
    this.toastr.info("Info!", msg, this.options);
    // this.config["panelClass"] = ["notification", "info"];
    // this.snackBar.open(msg, "", this.config);
  }

  warn(msg) {
    this.toastr.warning("warning!", msg, this.options);
    // this.config["panelClass"] = ["notification", "warn"];
    // this.snackBar.open(msg, "", this.config);
  }

  error(msg) {
    this.toastr.error("An error occured!", msg, this.options);
    // this.config["panelClass"] = ["notification", "error"];
    // this.snackBar.open(msg, "", this.config);
  }
}
