import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let isAuthenticated = false;
    return this.authService.getAuthStatusListener()
    .pipe(
      map((res) => {
        if (res) {
          return true
        } else {
          this.router.navigate(["/login"])
        }
      })
    )
    // this.authService.getAuthStatusListener().subscribe(
    //   (isAuth) => {
    //     console.log(isAuth);
    //     isAuthenticated = isAuth;
    //     // return isAuthenticated ? true : this.router.navigate(["/login"]);
    //   }
    // );
    // return isAuthenticated ? true : this.router.navigate(["/login"]);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let isAuthenticated = false;
    return this.authService.getAuthStatusListener()
    .pipe(
      map((res) => {
        if (res) {
          return true
        } else {
          this.router.navigate(["/login"])
        }
      })
    )
    // this.authService.getAuthStatusListener().subscribe((isAuth) => {
    //   console.log(isAuth);
    //   isAuthenticated = isAuth;
    //   return isAuthenticated ? true : this.router.navigate(["/login"]);
    // });
    // return isAuthenticated ? true : this.router.navigate(["/login"]);
  }
}
