import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HelperService } from "src/app/shared/helper.service";

declare interface RouteInfo {
  path?: string;
  title?: string;
  icon?: string;
  class?: string;
  type?: string;
  iconColor?: string;
  active?: boolean;
  children?: any[];
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },

  {
    title: "Transactions",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/transactions",
        title: "Transactions",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/settlements",
        title: "FLW Settlements",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/flw-verify",
        title: "Verify Transactions",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/advance-search",
        title: "Advance Search",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  // {
  //   title: "Manage Ads",
  //   icon: "fa-briefcase",
  //   iconColor: "text-primary",
  //   class: "",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/ad-upload",
  //       title: "Upload Ad",
  //       icon: "ni-planet text-blue",
  //       class: "",
  //       type: "link",
  //       iconColor: "text-primary",
  //       active: false,
  //     },
  //     {
  //       path: "/ad-page-list",
  //       title: "Ad Pages",
  //       icon: "ni-planet text-blue",
  //       class: "",
  //       type: "link",
  //       iconColor: "text-primary",
  //       active: false,
  //     },
  //     {
  //       path: "/ad-location-list",
  //       title: "Ad Locations",
  //       icon: "ni-planet text-blue",
  //       class: "",
  //       type: "link",
  //       iconColor: "text-primary",
  //       active: false,
  //     },
  //     {
  //       path: "/flw-verify",
  //       title: "Set Time Limit",
  //       icon: "ni-planet text-blue",
  //       class: "",
  //       type: "link",
  //       iconColor: "text-primary",
  //       active: false,
  //     }
  //   ],
  // },

  {
    title: "Fail ed Bookings",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/error-30-list",
        title: "Failed Bookings",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/failed-revenue",
        title: "Failed Revenue",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Validations",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/validation-device-list",
        title: "Validation Devices",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/on-train-validated-users",
        title: "Intrain Validated Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/on-train-issued-users",
        title: "Intrain Issues Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/manifest-table",
        title: "Manifest",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/validation-table",
        title: "Validations",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "User Groups",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/admin-list",
        title: "Admins",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/nrc-list",
        title: "NRC Users",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sid-list",
        title: "SID Users",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/tps-list",
        title: "TPS Users",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/cs-list",
        title: "CS Users",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/so-list",
        title: "SO Users",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Users Metrics",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/review-list",
        title: "Reviews",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/booked-users",
        title: "Booked Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/old-users",
        title: "Old Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "SC Data",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/sc-user-list",
        title: "Users",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sc-fetch-ticket",
        title: "Fetch Ticket",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/daily-sales-report",
        title: "Sales Report",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sales-report-equipment",
        title: "Report By Equipment",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "sales-transactions-report",
        title: "Daily Transactions",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/penalty-report",
        title: "Penalty Report",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Force Majeure",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/nrc-force-majeure-list",
        title: "NRC Force Majeure List",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/force-majeure-trigger",
        title: "Force Majeure Trigger",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/force-majeure-reason-list",
        title: "NRC Force Majeure Reasons",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Others",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/banner-message-list",
        title: "Banner Message",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/lts-list",
        title: "Latest Stable Version",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/stations",
        title: "Stations",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/pos-list",
        title: "Pos",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

];

export const SIDROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },

  {
    title: "Transactions",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/transactions",
        title: "Transactions",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/flw-verify",
        title: "Verify Transactions",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/advance-search",
        title: "Advance Search",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "SC Data",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/sales-report-equipment",
        title: "Report By Equipment",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/penalty-report",
        title: "Penalty Report",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Others",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/stations",
        title: "Stations",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/pos-list",
        title: "Pos",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
];

export const NRCROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },

  {
    title: "Transactions",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/transactions",
        title: "Transactions",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      }
    ],
  },

  {
    title: "Users Metrics",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [

      {
        path: "/booked-users",
        title: "Booked Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      }
    ],
  },

  {
    title: "Database Management",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/sales-report-equipment",
        title: "Sales By Equipment",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/daily-sales-report",
        title: "Sales Report",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Others",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/stations",
        title: "Stations",
        icon: "ni-pin-3 text-orange",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },



];

export const TPSROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },

  {
    title: "SC Data",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/sc-user-list",
        title: "Users",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sc-fetch-ticket",
        title: "Fetch Ticket",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/daily-sales-report",
        title: "Sales Report",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sales-report-equipment",
        title: "Report By Equipment",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "sales-transactions-report",
        title: "Daily Transactions",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/penalty-report",
        title: "Penalty Report",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Validations",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      // {
      //   path: "/validation-device-list",
      //   title: "Validation Devices",
      //   icon: "ni-planet text-blue",
      //   class: "text-primary",
      //   type: "link",
      //   iconColor: "text-primary",
      //   active: false,
      // },
      {
        path: "/on-train-validated-users",
        title: "Intrain Validated Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/on-train-issued-users",
        title: "Intrain Issues Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/manifest-table",
        title: "Manifest",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/validation-table",
        title: "Validations",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Users Metrics",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/review-list",
        title: "Reviews",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/booked-users",
        title: "Booked Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/old-users",
        title: "Old Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

];

export const SOROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },

];

export const CSROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },

  {
    title: "Transactions",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [

      {
        path: "/flw-verify",
        title: "Verify Transactions",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/advance-search",
        title: "Advance Search",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Failed Bookings",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/error-30-list",
        title: "Failed Bookings",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    path: "/old-users",
    title: "Old Users",
    icon: "ni-planet text-blue",
    class: "text-primary",
    type: "link",
    iconColor: "text-primary",
    active: false,
  },

  {
    title: "Users Metrics",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/review-list",
        title: "Reviews",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/booked-users",
        title: "Booked Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/old-users",
        title: "Old Users",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "SC Data",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/sc-user-list",
        title: "Users",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sc-fetch-ticket",
        title: "Fetch Ticket",
        icon: "ni-planet text-blue",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },

  {
    title: "Force Majeure",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/nrc-force-majeure-list",
        title: "NRC Force Majeure List",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      // {
      //   path: "/force-majeure-trigger",
      //   title: "Force Majeure Trigger",
      //   icon: "ni-planet text-blue",
      //   class: "text-primary",
      //   type: "link",
      //   iconColor: "text-primary",
      //   active: false,
      // },
      {
        path: "/force-majeure-reason-list",
        title: "NRC Force Majeure Reasons",
        icon: "ni-planet text-blue",
        class: "text-primary",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  sidA: boolean;
  sidStaff: boolean;
  nrc: boolean;
  tps: boolean;
  cs: boolean;
  so: boolean;

  constructor(private router: Router, private helperService: HelperService) {}

  ngOnInit() {
    this.getUserGroup();
    // this.menuItems = ROUTES.filter((menuItem) => menuItem);
    // this.router.events.subscribe((event) => {
    //   this.isCollapsed = true;
    // });
  }

  getUserGroup() {
    const userGroup = this.helperService.getUserGroup();
    // console.log(userGroup);
    switch (userGroup.id) {
      case 1:
        this.sidA = true;
        this.menuItems = ROUTES.filter((menuItem) => menuItem);
        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
        });
        break;
      case 2:
        this.sidStaff = true;
        this.menuItems = SIDROUTES.filter((menuItem) => menuItem);
        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
        });
        break;
      case 3:
        this.nrc = true;
        this.menuItems = NRCROUTES.filter((menuItem) => menuItem);
        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
        });
        break;
      case 4:
        this.tps = true;
        this.menuItems = TPSROUTES.filter((menuItem) => menuItem);
        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
        });
        break;
      case 10:
        this.cs = true;
        this.menuItems = CSROUTES.filter((menuItem) => menuItem);
        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
        });
        break;
      case 9:
        this.so = true;
        this.menuItems = SOROUTES.filter((menuItem) => menuItem);
        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
        });
        break;

      default:
        break;
    }
  }

  toggleNav(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }
}
