import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AuthService } from "./auth.service";
import { catchError, switchMap, filter, take } from "rxjs/operators";
import { throwError, Observable, BehaviorSubject, Subscription } from "rxjs";
// import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
protected token;
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const headers = req.headers
    //     .set('Content-Type', 'application/json');
    //   const authReq = req.clone({ headers });
    let token = this.authService.accessTokenSubject.getValue() || localStorage.getItem("tokenAccess")
      let authRequest = req;
      if (token) {
        authRequest = this.addToken(req, token);
        // authRequest = req.clone({
          //   headers: req.headers.set("Authorization", "Bearer " + token)
          // });
        }
        return next.handle(authRequest);
    // return next.handle(authRequest).pipe(
    //   catchError((error) => {
    //     // // console.log(error);
    //     if (
    //       // (error instanceof HttpErrorResponse && error.status == 401) ||
    //       error.error.detail == "Authentication credentials were not provided."
    //     ) {
    //       // // // console.log("I am in auth Interceptor If block");
    //       return this.handle401Error(req, next);
    //     } else {
    //       // // // console.log("I am in auth Interceptor else block");
    //       this.authService.logout();
    //       return throwError(error);
    //     }
    //   })
    // );
  }

  private addToken(request: HttpRequest<any>, token: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        // "Content-Type": "application/json"
      },
    });
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.authService.accessTokenSubject.next(null);

  //     return this.authService.onRefreshToken2().pipe(
  //       switchMap((token: any) => {
  //         this.isRefreshing = false;
  //         this.authService.accessTokenSubject.next(token.access);
  //         const expiresInDuration = 1200;
  //         const now = new Date();
  //         const expirationDate = new Date(
  //           now.getTime() + expiresInDuration * 1000
  //         );
  //         localStorage.setItem("tokenAccess", token.access);
  //         localStorage.setItem("expiration", expirationDate.toISOString());
  //         // this.refreshTokenSubject.next(token.access);
  //         return next.handle(this.addToken(request, token.access));
  //       })
  //     );
  //   } else {
  //     return this.authService.accessTokenSubject.pipe(
  //       filter((token) => token != null),
  //       take(1),
  //       switchMap((jwt) => {
  //         return next.handle(this.addToken(request, jwt));
  //       })
  //     );
  //   }
  // }
}
